import React, { useEffect, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import { motion, useAnimation } from 'framer-motion';
import "./App.css";

function DarkVariantExample() {
    const controls1 = useAnimation();
    const controls2 = useAnimation();
    const card1Ref = useRef(null);
    const card2Ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (entry.target === card1Ref.current) {
                        controls1.start({ x: 0, opacity: 1 });
                    }
                    if (entry.target === card2Ref.current) {
                        controls2.start({ x: 0, opacity: 1 });
                    }
                } else {
                    if (entry.target === card1Ref.current) {
                        controls1.start({ x: -100, opacity: 0 });
                    }
                    if (entry.target === card2Ref.current) {
                        controls2.start({ x: 100, opacity: 0 });
                    }
                }
            });
        });

        observer.observe(card1Ref.current);
        observer.observe(card2Ref.current);

        return () => {
            observer.unobserve(card1Ref.current);
            observer.unobserve(card2Ref.current);
        };
    }, [controls1, controls2]);

    return (
        <div className="container">
            <style>
                {`
                .custom-card {
                    border-radius: 25px;
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(1.6px);
                    -webkit-backdrop-filter: blur(1.6px);
                    overflow: hidden;
                    transition: transform 0.3s ease;
                }

                .custom-card img {
                    border-radius: 50%;
                    width: 40%;
                    margin: auto;
                    transition: transform 0.3s ease;
                }

                .card-text {
                    transition: transform 0.3s ease;
                }

                .awd:hover {
                    transform: scale(1.05);
                }
                `}
            </style>
            <div className="row justify-content-center row-mobile">
                <motion.div
                    className="col-12 col-md-4 mb-4 c1 order-2 order-md-1"
                    ref={card1Ref}
                    initial={{ x: -100, opacity: 0 }}
                    animate={controls1}
                    transition={{ duration: 0.5 }}
                >
                    <Card className="custom-card awd card-25k">
                        <Card.Img variant="top" src="3.webp" />
                        <Card.Body style={{ backgroundColor: 'transparent', height: '180px', marginTop: "-50px" }}>
                            <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem', fontFamily: "proxima_nova_rgbold", marginTop: "20px" }}>1<sup>st</sup> Runner Up</Card.Title>
                            <Card.Text className="mobile-font-size card-price" style={{ textAlign: 'center', color: 'white', fontSize: '2.0rem', fontWeight: "bolder" }}>
                                LKR 75,000
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </motion.div>
                <div className="col-12 col-md-4 mb-4 c1 order-1 order-md-2">
                    <Card className="custom-card awd card-125k">
                        <Card.Img variant="top" src="2.webp" />
                        <Card.Body style={{ backgroundColor: 'transparent', height: '180px', marginTop: "-50px" }}>
                            <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem', fontFamily: "proxima_nova_rgbold", marginTop: "20px" }}>Winner</Card.Title>
                            <Card.Text className="mobile-font-size card-price" style={{ textAlign: 'center', color: 'white', fontSize: '2.5rem', fontWeight: "bolder" }}>
                                LKR 125,000
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <motion.div
                    className="col-12 col-md-4 mb-4 c1 order-3 order-md-3"
                    ref={card2Ref}
                    initial={{ x: 100, opacity: 0 }}
                    animate={controls2}
                    transition={{ duration: 0.5 }}
                >
                    <Card className="custom-card awd card-25k">
                        <Card.Img variant="top" src="1.webp" />
                        <Card.Body style={{ backgroundColor: 'transparent', height: '180px', marginTop: "-50px" }}>
                            <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem', fontFamily: "proxima_nova_rgbold", marginTop: "20px" }}>2<sup>nd</sup> Runner Up</Card.Title>
                            <Card.Text className="mobile-font-size card-price" style={{ textAlign: 'center', color: 'white', fontSize: '2.0rem', fontWeight: "bolder" }}>
                                LKR 50,000
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </motion.div>
            </div>
        </div>
    );
}

export default DarkVariantExample;
