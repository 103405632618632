import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/montserrat/400.css";
import "./HomeR.css";

const HomeR = () => {
  const [imageIndex, setImageIndex] = useState(1);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    let interval;
    if (isHovering) {
      interval = setInterval(() => {
        setImageIndex(prevIndex => (prevIndex % 2) + 1);
      }, 700);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isHovering]);

  const handleRegisterClick = () => {
    window.open("https://social.hackx.lk/Register", "_blank");
  };

  const handleExpositionClick = () => {
    window.open("http://social.hackx.lk/TeamProposal", "_blank");
  };

  return (
    <section className="full-height-section"> {/* Full viewport height */}
      <Container className="h-100">
        <Row className="h-100 align-items-center g-0"> {/* No gutters */}
          <Col xs={12} md={6} className="order-md-1 order-2 d-flex flex-column justify-content-center align-items-center text-center leftCn" style={{ marginTop: "170px"}}>
            <motion.div
              initial={{ x: -1000 }}
              animate={{ x: 0 }}
              transition={{ duration: 1.5, type: 'spring', stiffness: 20 }}
            >
              <h1 style={{ color: "white", fontFamily: "Montserrat", fontWeight: "bold", fontSize: "90px", marginTop: "20px" }} className='hakx'>hackX 9.0</h1>
              <h4 style={{ color: "white", fontFamily: "proxima_nova_rgregular", opacity: "0.6" }} className='inter'>The Inter-University Startup Challenge</h4>
              <h1 style={{ color: "white", fontFamily: "proxima_nova_rgbold", fontWeight: "600", fontSize: "40px", marginTop: "20px" }} className='reg'>Registrations Are Now Open!</h1>
              <Row className="mt-3 justify-content-center flex-column flex-md-row custom-responsive-row">
                <Col xs={12} md="auto" className="mb-2 mb-md-0">
                  <Button className="custom-button" onClick={handleRegisterClick} style={{width:"200px"}}><span >Register Now</span></Button>
                </Col>
                <Col xs={12} md="auto" className="mb-2 mb-md-0">
                  <Button className="custom-button1" onClick={handleExpositionClick}><span>Proposal Template</span></Button>
                </Col>
              </Row>
            </motion.div>
          </Col>
          <Col xs={12} md={6} className="order-md-2 order-1 d-flex justify-content-center align-items-center rightCn" style={{ marginTop: "70px" }}>
            <motion.div
              initial={{ x: 1000 }}
              animate={{ x: 0 }}
              transition={{ duration: 1.5, type: 'spring', stiffness: 20 }}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <div style={{ display: 'flex', justifyContent: 'center' }}> {/* Centered div */}
                <img src={`m${imageIndex}.webp`} alt="Mascot" style={{ width: '180%', height: 'auto' }} className='img2' />
              </div>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeR;
